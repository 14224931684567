
@font-face {
	font-family: "Satoshi Bold";
	src: url("./assests/Fonts/Satoshi-Bold.woff") format("opentype");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "Satoshi Medium";
	src: url("./assests/Fonts/Satoshi-Medium.woff") format("opentype");
	font-weight: normal;
	font-style: normal
}

@font-face {
	font-family: "Satoshi Regular";
	src: url("./assests/Fonts/Satoshi-Regular.woff") format("opentype");
	font-weight: normal;
	font-style: normal
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar{
  padding: 20px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  max-width: 80%;
}

.navbar h1{
  color: #f1356d;
}

.navbar .links{
  margin-left: auto;
}

.navbar a{
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}

.navbar a:hover{
  color: #f1356d;
}

.links{
  display: flex;

}
.toggle-btn {
  display: block;
  width: 100%;
  background-color: transparent;
  border: none;
  color: black;
  cursor: pointer;
  text-align: left;
}

.bottom-items {
  bottom: 0;
  list-style: none;
  padding: 10px;
  background-color: white;
  margin: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  text-align: justify;
}

.bottom-items li {
  margin-bottom: 5px;
}

.timelineDiv{
  background-color: #FFFFFF;
  height: 100px;
  margin-bottom:10px;
}

.actbtns{
  margin-top: 70px;
  margin-left: 140px;
}

.actbtns1{
  margin-top: 70px;
  margin-left: 40px;
}

.inputwidth{
  width:700px;
  background-color: #F6F7FC;
}

.inputwidth1{
  width:70px;
  background-color: #F6F7FC;
}

.acpt-btn{
  background-color: #FF6B00;
}

.rqstbtn{
  color:#FF6B00
}

.tableform{
  margin-bottom: 50px;
}

.inputform1{
  width:300px;
  margin-right: 10px;
  background-color: #F6F7FC;
}

.inputform2{
  width:575px;
  margin-right: 10px;
  background-color: #F6F7FC;
}

.form-check{
  margin-bottom: 30px;
}

.labelform1{
  display: flex;
  text-align: justify;
}

.labelform1{
  display: flex;
  text-align: justify;
}

ul{
  list-style: none;
}

.reqstBtn{
  background-color:#FFE6C0 ;
  color:#CF8E00;
}

.navTab{
  color:black;
}

.smallWidth{
  width:20px;
}

.textalign{
  text-align: left;
}

/* .mainDiv{
  margin-left: 50px;
  margin-top: 20px;
} */

.divStyle{
  width:30%;
  height:450px;      
}

.margin{
  margin-right: 50px;
}

.div1{
  background: linear-gradient(236deg, #E3FFFC 32.00%, #FFF 100%);
}

ul{
  list-style: none;
}

.reqstBtn{
  background-color:#FFE6C0 ;
  color:#CF8E00;
}

.navTab{
  color:black;
}

.smallWidth{
  width:20px;
}

.textalign{
  text-align: left;
}

/* .mainDiv{
  margin-left: 50px;
  margin-top: 20px;
} */

.divStyle{
  width:30%;
  height:450px;      
}

.margin{
  margin-right: 50px;
}

.div1{
  background: linear-gradient(236deg, #E3FFFC 32.00%, #FFF 100%);
}

.parastyling{
  margin-right:90%;
}

.checkInput{
  margin-left: 50px;
}

.heading{
  text-align: left;
}

.parastyling{
  margin-right:90%;
}

.heading{
  text-align: left;
}

.cardimg{
  width:100px;
  margin-top: 70px;
}

.titleimg{
  margin-right: 2%;
}

.info{
  text-align: left;
  line-height: 1;
}

.infoDiv{
  margin-top:50px;
}

.cardBtn{
  border-radius: 53px;
  margin-top:100px;
}

.midBtnsDiv{
  position: relative;
  left:20rem;
}

.bottomBtnDiv{
  height:100px;
  position: relative;
  right:7.5rem;
}

.tabP{
  text-align: left;
}

.contactCard{
  width:300px;
  padding-top:20px;
}

.midcard{
  margin-top: 50px;
}

.textStyle{
  color:blue;
}

.textStyle1{
  text-decoration: underline;
}

.rightimg{
  position:relative;
  bottom: 35px;
  right:20px;
}

.total{
  text-align: left;
}

.progressBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.progressBarItem {
  flex: 1;
  height:100%;
  margin: 0 2px;
  border-radius: 5px;
  margin-left: 85px;
}

.colorItem {
  background-color: #95C2FF; 
}

.grayItem {
  background-color: #ccc;
}

.price-row{
  display: flex;
  justify-content: center;
}

.content{
  width: 50px;
  height:546px;
  margin:0 30px;
  background-color: #F6F7FC;
  text-align: start;
  border-radius: 
10px;
}

.disp{
  width: 98%;
  height:100px;
  margin-top:20px;
  margin-bottom: 20px;
  margin-left: 17px;
  background-color: #F6F7FC;
  text-align: start;
}

.pricing-line{
  display: flex;
  justify-content:flex-start;
  margin-left: 10px;
  position: relative;
  bottom:20px;
  
}

.tick-list {
  list-style-type: none;
  padding-left: 0;
  position: relative;
  top:50px;
}

.tick-list li::before {
  content: '\2713'; 
  display: inline-block;
  width: 1em;
  margin-right: 0.5em;
}

.pricingbtn{
  margin-top: 190px;
  margin-left: 60px;
}

.amount{
  position: relative;
  top:50px;
}

.textnoti{
  margin-left:10px;
}

.billing{
  position: relative;
  left:1400px;
  bottom: 50px;
}

.inputform3{
  width:250px;
  margin-right: 10px;
  background-color: #F6F7FC;
 
}

.tgBtn{
  position: relative;
  bottom:130px;
  left:600px;

}

.togglebtn:hover{
  background-color:#FF6B00;
}

.datepick{
  display: flex;
}

.inputform4{
  width:280px;
  margin-right: 10px;
  background-color: #F6F7FC;
}

/* Header */
.quote-body{
  width:100%;
 display:flex;
  
}

.main-header-content{
  padding:20px 0;
}

.pageHeader {
  display:flex;
  justify-content: space-between;
  padding:0 36px;
}

.pageHeader h1{
  font-family: 'Satoshi Medium';
  font-size: 40px;
  font-weight: 500;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  color:#032020;
  margin-bottom:0;

}

.widget-button .quoteBtns {
    border-radius: 60px;
    background-color: #f6f7fc;
    padding: 6px 16px 6px 10px;
} 

 .widget-button a{
    display:flex;
    gap:8px;
    text-decoration-line: none;
  }

.widget-button a span{
    color:#032020;
    text-decoration-line: none;
    font-family: 'Satoshi Medium';
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    opacity:15%;
}

.user{
    padding:0 36px;
    margin-top:10px;
}

.user span{
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color:#FF6B00;
}
  
.user .rqstbtn {
  color: #ff6b00;
  border-radius: 53px;
  background: #fff;
  border: 1px solid rgba(3,32,32,0.15);
}

/* Main Body */
.page-body {
  padding:24px 24px;
  background-color: #f6f7fc;
}

.page-body.content-ht{
  height:100vh;
}

/* Buttons */
.bottom-button{
  padding: 30px 24px;
   margin-top: 24px;
   display:flex;
   gap:12px;

}

.bottom-button.right{
  float:right
}

.btn-save{
  border:none;
  padding:0;
}

.btn-back,.btn-cancel{
  border-radius: 12px;
  padding: 2px 0px;
  border: 1px solid #032020;
}

.btn-save span{
  padding:9px 32px 9px 32px;
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color:#fff;
  background: #FF6B00;
  border-radius:50px;
}

.btn-back span, .btn-cancel span{
  padding:15px 32px 15px 32px;
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color:#000;
  border-radius:50px;
}

.toggleSwitch{
  background-color: orangered;
  color:orangered;
  width:52px !important;
  height:32px !important;
  position: relative;
}

.main-wrap .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.main-wrap .sidebar h1 {
  color: white;
}

.main-wrap .portfolio {
  background-color: white;
  position: absolute;
  top: 0;
  left: 150px;
  right: 0;
  bottom: 0;
  margin-left: 54px;
  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;
}

img.sidebar-toggle{
  position: fixed;
  top: 39px;
  left: 20px;
}

input#slide-sidebar {
 display: none;
}

.main-wrap input:checked ~ .portfolio { 
  left: 0;
}

.main-wrap input:checked ~ label {
  left: 0;
}

.main-wrap label {
  z-index: 2;
  top: 0;
  left: 150px;
  padding:10px;
  -moz-transition: left 0.5s ease;
  transition: left 0.5s ease;
}

.main-wrap label:hover {
  cursor:pointer;
}

.record-row{
  background: #F2F2F2;
  position: relative;
}

.list{
  padding:16px 24px;
}
.list table tbody tr td.action-buttons a img{
  cursor: pointer;
}

.arrow-up, .arrow-down {
  cursor: pointer;
  padding-left: 3px;
  text-align: center;
  line-height: 20px;
  font-size: 7px;
  color: #032020;
  margin-top: -14px;
}

.table-arrow{
  display: inline-block;
}

.main-wrap .sidebar {
  display:block
}

.main-wrap .portfolio{
  position: absolute;
}

.user-details .first-row, .user-details .second-row,.user-details .third-row,.user-details .fourth-row,.user-details .fifth-row{
  display: flex;
}

.user-details .first-row .rows-content,.user-details .second-row .rows-content,.user-details .third-row .rows-content,
.user-details .fourth-row .rows-content,.user-details .fifth-row .rows-content{
  width:100%;
}

@media only screen and (max-width: 768px) {
/* .main-wrap .sidebar {
  display:none
} */

.main-wrap .portfolio{
  position: relative;
  margin-left:0;
  left:0;
}

.quote-body{
  display: block;
}

.user-details .first-row .rows-content,.user-details .second-row .rows-content,.user-details .third-row .rows-content,
.user-details .fourth-row .rows-content,.user-details .fifth-row .rows-content{
  padding-top:12px;
}

.user-details .first-row, .user-details .second-row,.user-details .third-row,.user-details .fourth-row,.user-details .fifth-row{
  display: block!important;
  padding: 0 !important;
  margin:0;
  height:auto!important;
}

.pageHeader h1 {
  font-size:26px;
}
.user-details h2 {
  font-size:22px!important;
}

.btn-back span, .btn-cancel span {
  padding:0 8px;
}
.btn-save span {
  padding: 14px 19px;
  width: 100%;
  display: block;
}
.btn-save{
  background: none;
}
.btn-back{
  padding:2px 11px;
}
.main-wrap .sidebar {
  position: relative;
}
img.sidebar-toggle{
  display: none;
}
}

@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .list{
  padding: 0;
 }

}
