.PartnerLabel{
    padding: 0!important;
    padding-top: 25px!important;
    padding-right: 35px !important;
}
.partnersListDiv{
    margin: 20px 0!important;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
    padding-top: 3px;
}
.commissionDiv{
    padding-top: 20px;
}
.OutsidePartCommMainDiv{
    display: flex;
}
.OutsidePartComm{
    width: 48%;
    margin: 1%;
}

.OutsidePartComm input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
}
.droneOpInputButton{
    display: inline-flex;
}
.droneOpInputDiv{
    content: "";
    display: inline-flex;
    top: 2px;
    position: relative;
    left: 10px;
}
.outsidePartComLabel{
    padding-left: 0px!important;
}

.inputPop{
    margin-left: 10px !important;

}

.SelctPartnrLabel{
    padding-left: 0!important;
   
}
.partnersTable{
    padding: 10 0px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.greyBackgroundRadio {
    position: relative;
    top: 5px;
    padding: 12px;
    left: 5px;
    margin-right: 5px;
    background: #d9dede;
    border:none;
  }
  
  .greyBackgroundRadio:checked {
    background: #000000;
  }
  

  @media only screen and (max-width: 768px) {
    .partnersTable{
        overflow-x: scroll;
        display: flex;
        flex-direction: column;

    }
    .OutsidePartCommMainDiv{
        flex-direction: column;
    }
  }