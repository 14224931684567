body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* organization record */

.organization-details .first-row{
  display: flex;
}
.form-head.org-details {
  border-bottom: 1px solid #032020;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
}


.organization-details .rows-content input {
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #032020;
  background-color: #f6f7fc;
}

.organization-details .rows-content .date {
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #032020;
  background-color: #f6f7fc;
  height: 50px;
  padding: 8px 16px;
  border-radius: 5px;
}

.organization-details
  .rows-content
  .date
  .react-datepicker__input-container
  input[type="text"] {
  height: 27px;
  padding: 0;
  border: none;
  margin-top: 0;

  outline: none;
}

.organization-details .rows-content .select {
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  color: #032020;
  background-color: #f6f7fc;
  margin-top: 8px;
  height: 50px;
  padding: 8px 16px;
}

.organization-details .rows-content .select .form-control {
  background: #f6f7fc;
  border: none;
  padding: 8px 16px;
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  border: none;
  color: #032020;
  background-color: #f6f7fc;
  margin-top: 8px;
  padding: 0;
  -webkit-appearance: auto;
}

.organization-details .rows-content .form-control {
  height: 50px;
  padding: 8px 16px 8px 16px;
  font-weight: 500;
}

.organization-details .rows-content select {
  height: 50px;
  background: #f6f7fc
    url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M6 8l4 4 4-4" stroke="%23333" stroke-linecap="round" stroke-linejoin="round"/></svg>')
    no-repeat;
  background-position: right 16px center;
  background-size: 23px;
  padding: 8px 16px 8px 16px;
  cursor: pointer;
  border-radius: 5px;
}

/* Admin Organization Record */

.admin-organization-record {
  background-color: white;
  padding: 30px 0px;
  border-radius: 10px;
  margin-top: 24px;
}

.admin-details .rows-content input {
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: left;
  color: #032020;
  background-color: #f6f7fc;
}

/* right plan */

.plan-organization-record {
  background-color: white;
  padding: 30px 24px;
  border-radius: 10px;
  margin-top: 24px;
}

.plan-details .form-head-organisation {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.pageHeading.right-plan {
  border-bottom: none;
  padding-bottom: 0;
}

.form-head-organisation .plan-heading p {
  font-family: "Satoshi Medium";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #302030;
  margin-bottom: 0;
}

.form-head-organisation .org-btns {
  display: flex;
  gap: 12px;
}

.right-plan-labels {
  border: 2px solid lightslategray;
  height: 37px;
  padding: 6px 14px;
  gap: 12px;
  display: flex;
}

.right-plan-labels span {
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #302030;
}

.organization-content.right-plan {
  margin-top: 24px;
}

.organization-content.right-plan .btn.btn-success.activebtn {
  background-color: #ff6b00;
  border: none;
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 50px;
  padding: 15px 32px 15px 32px;
  margin-left: 10px;
  margin-top: 190px;
}

.organization-content.right-plan .activebtn {
  background-color: #004747;
    color: #fff;
    border: none;
    font-family: "Satoshi Medium";
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 44px;
    padding: 9px 32px 10px 32px;
    margin-left: 10px;
    margin-top: 190px;
    width: 100%;
    text-align: center;
    align-items: center;
    align-content: center;
    margin: 100% 0% 0% 0%;
}

.organization-content.right-plan .btn.btn-success.activebtn {
  background-color: #FF6B00;
  color: #fff;
  border: none;
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 44px;
  padding: 9px 32px 10px 32px !important;
  margin-left: 10px;
  margin-top: 190px;
  width: 100%;
  text-align: center!important;
  align-items: center;
  align-content: center;
  margin: 100% 0 0 0 !important;
}

/* plan sub */

.organization-plan {
  background-color: white;
  padding: 30px 24px;
  border-radius: 10px;
  margin-top: 24px;
}

.organization-plan.plan-subscription {
  display: flex;
  justify-content: space-between;
}

.organization-plan.plan-subscription {
  h4 {
    font-family: "Satoshi Medium";
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
    color: #302030;
  }
  p {
    font-family: "Satoshi Medium";
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0;
    color: #302030;
  }
}

.organization-plan.plan-subscription p span {
  color: #032020;
  opacity: 60%;
}

.organization-plan.plan-subscription .billing-btn {
  background-color: #004747;
  color: #fff;
  border: none;
  font-family: "Satoshi Medium";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 50px;
  padding: 15px 32px 15px 32px;
}

.inputWidth {
  width: 25%;
}

.discountDiv {
  margin-top: 50px;
}

.required {
  color: red;
}

.project-creation-container {
  position: relative;
}

.blur {
  filter: blur(1px);
}

.loader-overlay {
  position: relative;
  top: 700px;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.organization-content {
  .amount {
    position: relative;
    top: 40px;
    text-align: center;
    display: inline-flex;
    gap: 5px;
    align-items: center;
    align-content: center;
    width: 100%;
  }

  .pricingHeading {
    color: #ff6b00;
    font-size: 12px;
    margin-bottom: 0;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }

  .tick-list {
    list-style-type: none;
    padding-left: 0;
    position: relative;
    top: 45px;
    width: 100%;
    text-align: justify;
  }

  .content {
    width: 50px;
    height: 100%;
    margin: 0 5px;
    background-color: #f6f7fc;
    text-align: start;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .main-wrap .portfolio{
    position: relative;
    margin-left:0;
    left:0;
  }
  
  .quote-body{
    display: block;
  }
  .admin-details .first-row, .admin-details .second-row, .organization-details .first-row{
    display: block!important;
  }
  
  .organization-details .first-row .rows-content,.organization-details .second-row .rows-content,.organization-details .third-row .rows-content,
  .organization-details.fourth-row .rows-content,.organization-details .fifth-row .rows-content{
    padding-top:12px;
  }
  
  .organization-details.first-row, .organization-details .second-row,.organization-details .third-row,.organization-details .fourth-row,.organization-details .fifth-row{
    display: block!important;
    padding: 0 !important;
    margin:0;
    height:auto!important;
  }
  
  .pageHeader h1 {
    font-size:26px;
  }

  .organization-details h2 {
    font-size:22px!important;
  }

  .content{
    width: 96% !important;
    margin-top:12px!important;
  }

  .price-row{
    flex-direction: column;
  }

  .organization-details .first-row{
    display:block;
  }

  .admin-details .first-row, .admin-details .second-row{
    display:block;
  }

  .bundle-address-data-table table thead tr, .bundle-address-data-table table tbody tr{
    display:flex;
    flex-direction: row;
  }

  .btn.bundle-his-Btn {
    padding: 3px 12px!important;
  }

  .bundle-address-data {
    margin-top: 24px;
    width: 100%;
    overflow-x: scroll;
    background: #F6F7FC;
    padding:6px 12px;
}

  .form-head-organisation .plan-heading h2 {
    font-size:26px;
  }

  .inputWidth{
    width:100%;
  }

  .plan-organization-record {
    padding:12px 24px;
  }

}

@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .organization-content{
     .amount{
      display: block;
      top:20px;
      text-align: left;
    }
    .tick-list li{
      font-size:17px;
    }
  }

}
