.generalInfo{
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top:12px;
}

.ReactModal__Content.ReactModal__Content--after-open{
  height:auto!important;
}

.general-info{
  margin-top:12px;
}

.general-info h4{
  font-family: 'Satoshi Bold';
  color: #032020;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  margin-bottom:0;
}

.general-info  p{
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  margin-bottom:0;
  color: #032020;
  opacity: 60%;
}

.bundle-address-data{
    margin-top:24px;
}

.bundle-address-data h4{
  font-family: 'Satoshi Bold';
  color: #032020;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0em;
  margin-bottom:0;
}

.bundle-address-data-table{
  margin-top: 24px;
  background: #F6F7FC;
  border-radius: 10px;
}

.bundle-address-data-table table{
  width:100%;
}

.bundle-address-data-table table thead tr th{
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color:#032020;
  padding:16px 16px;
}

.bundle-address-data-table table thead tr th.opac{
  opacity:60%;
 }
 
 .bundle-address-data-table table thead tr{
  border-bottom:1px solid rgba(3,32,32,0.15);
 }

 .bundle-address-data-table table tbody tr td{
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color:#032020;
  padding: 16px 16px;
 }

.bulk-address-data-table{
  padding: 24px 25px;
  background: #fff;
  border-radius:10px;
}

.organization-content.right-plan .btn.btn-success.activebtn{
  background-color: #FF6B00;
  border:none;
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 50px;
  padding: 15px 32px 15px 32px;
  margin-left:10px;
  margin-top:190px;
}

.bundle-save{
  padding:0 24px;
}

.saveBndleBtn{
  background-color: #004747;
  color: white;
}

.saveBndleBtn:hover{
  background-color: #004747;
}
@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .bundle-address-data-table {
    overflow-x: scroll;
  }
}