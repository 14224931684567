.timebar{
  background-color: white;
  padding: 30px 24px;
  border-radius: 10px;
  width: 20%;
}

.timebar-content{
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.timebar-content .sub-content{
  display: flex;
  gap: 12px;
}

.timebar-content .sub-content img{
  background-color: #FF6B00;
  border-radius: 92.31px;
  width:24px;
  height:24px;
  padding: 5px 5px;
}

.timebar-content .sub-content{
  font-family: 'Satoshi Medium';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  color:#032020;
}

.timebar-content .sub-content label h6{
  font-family: 'Satoshi Medium';
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  color:#032020;
  margin-bottom:0;
}

.timebar-content .sub-content .tab-label{
  display:flex;
  gap:12px;
}
  
.timebar-content .sub-content .tab-label h6{
  margin-bottom:0;
}
  
.timebar-content .sub-content .tab-label::after {
  content: "\276F";
  width: 16px;
  height:17px;
  transform: rotate(90deg);
  transition: all 0.35s;
}

.timebar-content{
  /* padding: 0 24px; */
  padding: 0 0px!important;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.timebar .timebar-content .sub-content input[type="checkbox"]:checked {
  background-color: #FF6B00;
  opacity: 100%;
}

.timebar .timebar-content .sub-content input[type="checkbox"] {
  appearance: none;
  border-radius: 50%;
  width: 20px!important;
  height: 20px!important;
  border: 1px solid #FF6B00;
  outline: none;
  position: relative;
  /* opacity: 15%;
  transform: scale(1.2); */
  opacity: 1!important;
  transform: scale(1.2);
  background: #f6f7fc;
  z-index: 99!important;
  padding: 10px!important;
}

.sub-content {
  position: relative;
}

.sub-content:not(:last-child)::after {
  content: "";
  position: absolute;
  /* top: calc(105% + -16px);
  left: 5%;
  height: 39px;
  width: 2px; */
  top: 27px!important;
  left: 10px!important;
  height: 100%!important;
  width: 2px!important;
  background-color: #FF6B00;;
}

.timebar .timebar-content .sub-content input[type="radio"]:checked {
  background-color: #FF6B00;
  opacity: 100%;
}

.timebar .timebar-content .sub-content input[type="radio"] {
  appearance: none;
  border-radius: 50%;
  width: 20px!important;
  height: 20px!important;
  border: 1px solid #FF6B00;
  outline: none;
  position: relative;
  opacity: 1!important;
  transform: scale(1.2);
  background: #f6f7fc!important;
  z-index: 99!important;
  /* background-color: #032020; */
  padding: 10px!important;
}
