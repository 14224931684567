
.accordionerror {
  border: 1px solid  #C70039;
}
  
.CheckInputInsProduct{
  position: relative;
  top: 0px!important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px;
}