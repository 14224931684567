
/* project details */
.project-details-content, .user-details-content, .organization-details-content, .admin-leaderdetails-content, .project-leaderdetails-content, .quote-content{
    padding:0 24px;
}

.project-details h2, .user-details h2, .organization-details h2, .admin-details h2, .form-head-organisation .plan-heading h2, .projectleader-details h2{
    font-family:'Satoshi Medium';
    font-size: 32px;
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color:#302030;
    padding-bottom:16px;
}

.project-details .first-row,.user-details .first-row, .user-details .second-row, .organization-details .first-row, .organization-details .second-row, .admin-details .first-row,
.admin-details .second-row, .projectleader-details .first-row, .quote-details .first-row, .quote-details .second-row{
    display:flex;
    gap:16px;
    padding: 10px 0;
}

.project-details .first-row .rows-content, .project-details .third-row  .rows-content, .project-details .fourth-row  .rows-content, .user-details .first-row .rows-content,.user-details .second-row .rows-content, .user-details .third-row .rows-content, .user-details .fourth-row .rows-content, .user-details .fifth-row .rows-content,
.organization-details .first-row .rows-content,
.organization-details .second-row .rows-content,
.organization-details .third-row .rows-content,
.organization-details .fourth-row .rows-content,.admin-details .first-row .rows-content,
.admin-details .second-row .rows-content, .projectleader-details .first-row .rows-content, .projectleader-details .second-row  .rows-content, .quote-details .first-row .rows-content, .quote-details .second-row .rows-content{
    width:100%;
}

.project-details .rows-content label, .user-details .rows-content label, 
.organization-details .rows-content label, .admin-details .rows-content label, .projectleader-details .rows-content label, .quote-details .rows-content label {
    font-family: 'Satoshi Bold';
    color:#032020;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    padding:0;
}

.project-details .second-row, .project-details .third-row, .project-details .fourth-row, .user-details .second-row, .user-details .third-row, .user-details .fourth-row, .user-details .fifth-row, .admin-details .second-row, .organization-details .second-row,
.organization-details .third-row,
.organization-details .fourth-row, .projectleader-details .second-row{
    padding:10px 0;
 }

.project-details .third-row, .project-details .fourth-row, .user-details .third-row, .user-details .fourth-row,.user-details .fifth-row, .admin-details .second-row, .organization-details .third-row,
.organization-details .fourth-row,.projectleader-details .second-row {
   display:flex;
   gap:16px;
}

.project-details .rows-content input.form-control, .project-details .rows-content select.form-control, .project-details .rows-content textarea.form-control, .user-details .rows-content input.form-control, .user-details .rows-content textarea.form-control,
.organization-details .rows-content input.form-control,
.organization-details .rows-content textarea.form-control, .admin-details .rows-content input.form-control,
.admin-details .rows-content textarea.form-control, .projectleader-details .rows-content input.form-control,  .projectleader-details .rows-content textarea.form-control{
    padding:8px 16px;
}

.project-details .rows-content input, .project-details .rows-content select, .user-details .rows-content input, .projectleader-details .rows-content input {
    font-family: 'Satoshi Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color:#032020;
    background-color: #F6F7FC;
    border-radius:5px;
}
  
.project-details .rows-content textarea{
    font-family: 'Satoshi Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    height:128px;
    color:#032020;
    background-color: #F6F7FC;
    border-radius:5px;
}

.project-details .rows-content .date{
    font-family: 'Satoshi Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    border:none;
    color:#032020;
    background-color: #F6F7FC;
    height:50px;
    padding:0;
}
.project-details .rows-content .date .react-datepicker-wrapper .react-datepicker__input-container{
    padding:8px 16px;
}
.project-details .rows-content .date p{
    padding:8px 16px;
    margin-bottom:0;
}
 
.project-details .rows-content .date .react-datepicker__input-container input[type="text"]{
    height:27px;
    border:none;
}
 
.project-details .rows-content .select{
    font-family: 'Satoshi Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-top:8px;
    border:none;
    color:#032020;
    background-color: #F6F7FC;
    margin-top:8px;
    height:50px;
    padding:8px 16px;
}

.project-details .rows-content .select .form-control{
    background: #F6F7FC;
    border:none;
    padding: 8px 16px;
    font-family: 'Satoshi Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-top:8px;
    border:none;
    color:#032020;
    background-color: #F6F7FC;
    margin-top:8px;
    padding:0;
    -webkit-appearance: auto;

}
.required{color:red;} 

.rows-content.error input[type="text"],
.rows-content.error input[type="email"],
.rows-content.error input[type="password"],

.rows-content.error .select{
  border: 1px solid  #C70039;
}

.rows-content.error .date{
  border: 1px solid  #C70039;
}

.error-message {
  color:  #C70039;
  font-size: 12px;
}

.error-border {
    border: 1px solid  #C70039;
  }

 
@media only screen and (max-width: 768px) {
.main-wrap .portfolio{
    position: relative;
    margin-left:0;
    left:0;
}

.quote-body{
    display: block;
}

.project-details-content .first-row .rows-content,.project-details-content .second-row .rows-content,.project-details-content .third-row {
    padding-top:12px;
    width: 100%!important;
}
    
.project-details-content .first-row, .project-details-content .second-row,.project-details-content .third-row{
    display: block!important;
    padding: 0 !important;
    margin:0;
    height:auto!important;
}

.pageHeader h1 {
    font-size:26px;
}

.project-details h2 {
    font-size:22px!important;
}
}
    