body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.roleSelect {
  height: 50px;
  width: 120px;
  border-radius: 1rem;
  margin-left: 8px;
  margin-right: 8px;
  border: none;
  background-color: #f6f7fc;
}

.invite-btn {
  border-radius: 2rem;
  width: 100px;
  height:50px !important;
}

.disp {
  width: 98%;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 17px;
  background-color: #f6f7fc;
  text-align: start;
}

.edit-modal-buttons{
  display:flex;
  gap:12px;
  float:right;
  margin-top:20px;
}

.btn.bulkUploadBtn{
  background: #004747;
  padding: 2px 15px 3px 15px;
  font-size: 14px;
  margin-top: 8px;
}

.bulk-address-data table{
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}


.modal-submit-btn{
  margin-top: 20px;
  width: 100px;
  border-radius: 20px;
  background-color: #004747;
}

.modal-submit-btn span{
font-family: 'Satoshi Medium';
font-size: 14px;
font-weight: 500;
line-height: 21px;
letter-spacing: 0em;
}

.modal-cancel-btn{
  margin-top: 20px;
  width: 100px;
  border-radius: 20px;
  background-color: #fff;
  color:#032020;
}

.modal-cancel-btn span{
  font-family: 'Satoshi Medium';
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
}

.modal-header {
  display: flex;
  gap: 12px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(3, 32, 32, 0.15);
  padding: 0 24px 10px 24px;
}
.modal-header h2 {
  font-family: "Satoshi Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  margin-bottom: 0;
  color: #032020;
  padding-bottom: 16px;
}
.ReactModal__Content.ReactModal__Content--after-open {
  border-radius: 10px !important;
  padding: 25px 0px !important;
  height: auto !important;
  z-index: 9999 !important;
}

.ReactModal__Content--after-open {
  width: 90% !important;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 1111 !important;
}
.ModalContentDiv {
  padding: 10px 25px;
  h3 {
    margin: 5px 0 25px 0;
  }
  h6 {
    margin-top: 25px;
    margin-bottom: 5px;
  }
  h5{
    margin: 25px 0 5px 0;
  }
  .MainLabel {
    margin: 25px 0 5px 0;
  }
}
.buttonContainer {
  margin-top: 15px;
  margin-bottom: 0px;
  border-top: 1px solid #DDDDDD;
  padding-top: 25px;
  text-align: right;
  button {
    margin: 0 5px;
  }
}
.QuoteinfoContainer {
  display: flex;
}
.infoSection {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 50%;
}
.ProjectNames {
  margin-bottom: 5px;
}
.closeBtn {
    top: 0;
    right: 25px;
}


.invite-content{
  display:flex;
  justify-content: space-between;
}

.invite-content .invite-content-buttons{
  display: flex;
  border: 1px solid rgba(3,32,32,0.15);;
  padding: 12px 30px 6px 30px;
  border-radius: 50px;
  gap: 12px;
}

.invite-content-buttons .dropdown select{
    margin: 0;
    padding: 10px 24px 12px 26px;
    width: 100%;
    background-color: #f6f7fc;
    border-radius:51px;
    border:none;
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    color:#032020;
}

.searchBar{
    border:none;
}

.searchBar{
    font-family: 'Satoshi Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    color:#032020;
    opacity: 60%;
}

.major{
     margin-top:54px;
}

.invite-record{
    background-color: white;
    padding: 30px 24px;
    border-radius: 10px;
    margin-top: 24px;
}

.invite-button{
    background:#004747;
    color:#fff;
    padding: 8px 32px 8px 32px;
    border-radius: 50px;
}
.invite-button span{
    font-family: 'Satoshi Medium';
    font-size: 14px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;

}

.invite-userlist-record{
    column-count: 2; 
    column-gap: 10px;
}
.invite-userlist-data {
    break-inside: avoid; 
    box-sizing: border-box;
    padding: 10px;
}
.project-creation-container {
  position: relative;
}

.blur {
  filter: blur(1px);
}

.loader-overlay {
  position: relative;
  top: 700px;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.delete-content .btn{
  border: none;

}
@media only screen and (max-width: 768px) {
  .invite-userlist-record{
    column-count: 1; 
  }
  .invite-content{
   flex-direction: column;
  }
  .invite-content .invite-content-buttons{
   flex-direction: column;
   margin: 11px 0;
  }
  .btn.bulkUploadBtn{
    font-size:10px;
  }
  .edit-modal-buttons {
    flex-direction: column;
  }
  .modal-header h2 {
    font-size: 22px;
  }
  .ReactModal__Content.ReactModal__Content--after-open{
    width:70%!important;
  }
}