img{
    border: none;
}

.form-head .list-address-build{
    display: flex;
    justify-content: space-between;
}

.main-form-head{
    margin-top:20px;
}

@media only screen and (max-width: 768px) {
    .form-head .list-address-build{
        margin-top:11px;
    }
}
