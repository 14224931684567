body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Dashboard  */

.dashboard-record {
  background-color: white;
  padding: 30px 0;
  border-radius: 10px;
}

.dashboard-details {
  padding: 0 25px;
}

.dashboard-record .dashboard-details h2 {
  font-family: "Satoshi Medium";
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff6b00;
  padding-bottom: 16px;
  border-bottom: 1px solid (3, 32, 32, 0.6);
}

.welcome-section .rows {
  display: flex;
  gap: 24px;
  text-align: center;
}

.welcome-section .rows .welcome-content {
  width: 50%;
}

.welcome-section .rows .welcome-content h2 {
  text-align: center;
  font-family: "Satoshi Bold";
  color: #032020;
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
}

.welcome-section .rows .welcome-content p {
  font-family: "Satoshi Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0;
  margin: 0;
}

.welcome-section .rows .welcome-content .btn {
  margin-top: 12px;
}

.welcome-section .rows .welcome-content .btn .anchorBtn {
  color: #fff;
  background-color: #004747;
  border-radius: 50px;
  padding: 16px 16px 16px 16px;
  text-decoration-line: none;
}

.welcome-section .rows .welcome-content .btn .anchorBtn span {
  font-family: "Satoshi Bold";
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0em;
}

.modalContactDUser {
  .modal-header {
    h4{
        color: #e47a00 !important;
        font-size: 24px !important;
        font-weight: 500;
    }
  
    padding: 10px 24px 10px 24px;
  }
  .modal-dialog {
    max-width: 40% !important;
  }
  .modal-header h2 {
    padding-top: 5px;
    padding-bottom: 0px;
  }
  .modal-content {
    margin-top: 3% !important;
    padding: 7px 7px !important;
    b {
      font-size: 16px;
      font-family: sans-serif;
    }
    h5,
    h6 {
      margin-top: 10px;
    }
  }
}

.mainParentDiv{
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: column;
  gap:24px;
}
.subParentDiv1{
   border: 1px solid #d5d0d0;
   width:100%;
   padding:16px 0 16px 0;

}
.DivPadding{
  padding:16px 24px;
}
.subParentDiv2{
  width: 100%; 
  border: 1px solid #d5d0d0;
  padding:16px 0 16px 0;
}
.portalParentDiv{
  display: flex;
  width: 100%;
  margin-top: 12px;
}
  
.portalParentDiv1{
  width: 100%;
  margin-right: 20px; 
  border: 1px solid #d5d0d0!important;
}    
.nameLabel{
   width: 40%;
}

.homedDataDiv{
  width:55%;
  font-size:15px;
}

.dataDiv{
  width: 55%;
  font-size:15px
}

.mainDiv{
  display: inline-flex;
  width: 100%;
}


.subParentDiv2 .projectlist-details-table-content .list-sub-accordian .row-wrapper .inner-table td{
  width:25%;
  padding:2px 20px
}

.subParentDiv2 .projectlist-details-table-content .list-sub-accordian-row{
  margin:0;
}
@media only screen and (max-width: 768px) {
  .welcome-section .rows{
    display: flex;
    flex-direction: column;
  }
  .welcome-section .rows .welcome-content{
    width: 100%;
  }
  .welcome-section .rows .welcome-content .btn .anchorBtn span {
    font-size:14px;
  }
  .mainParentDiv{
    flex-direction: column;
  }
  .subParentDiv1{
    width: 100%;
    margin-right:0;

  }
  .DivPadding{
    padding:12px;
  }

  .subParentDiv2{
    width: 100%;
    margin-left: 0px;
    margin-top:30px;
    border: 1px solid #d5d0d0;
  }
  .portalParentDiv1{
    margin-right: 0px; 
  }
  .nameLabel{
     font-size:12px;
     font-weight: bold;
  }
  .homedDataDiv{
    width:55%;
    font-size:10px;
  }
  .dataDiv{
    width: 55%;
    font-size:10px
    }
    
  
}
@media only screen and (min-width: 2000px) and (max-width: 3000px) {
  .projectlist-details-table-content {
    overflow-x: scroll;
  }
  .mainDiv{
    display: flex;
    width: 100%;
    gap:40px;
  }
  .nameLabel{
    font-size:9px;
 }
 .homedDataDiv{
  font-size:9px;
 }
}



@media (max-width: 1600px) {
  .subParentDiv2{
    width:100%;
  }
  .subParentDiv1{
    width:100%;
  }

}