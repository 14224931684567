 
.modal-content {
    display: flex;
    flex-direction: column;
}

.bulkUploadBtn{
    border-radius: 50px;
}
   
.closeBtn{
   border: none;
   background:none;
   align-self: flex-end;
   cursor: pointer;
}

.submit-btn{
   margin-top: 20px;
   width: 100px;
   border-radius: 10rem;
 }

.fileDiv{
   width: 100%;
   margin-top:20px;
   margin-left: 1px;
   height:90px;
   background-color: #F6F7FC;
}

.btn1{
   margin-right: 40px;
}

.bin{
   border: none;
   background: none;
}

.r1{
   margin-top: 20px;
}

.r2{
   position: relative;
   right:40px;
}
 
  /******** address details css**********/

.dltBtnDiv{
    align-items: end;
    margin-bottom: 20px;
    margin-left:85%;
}

.dltBtn{
    text-decoration: underline;
}

.addressDiv{
    margin-bottom: 70px;
    background-color: #F6F7FC;
    width:95%;
    margin-left: 20px;
    border-radius: 2rem;
    padding-bottom: 5px;
}

.addressDiv-content{
    margin-top:24px;
    padding: 16px;
    background: #F6F7FC;
    border-radius: 10px;
}

.addressDiv-content table{
    width:100%;
}

.addressDiv-content table tbody tr td{
   vertical-align: baseline;
}

.addressDiv-content table tbody tr td input{
    margin-top:20px;
    
}

.addressDiv-content table tbody tr td input[type="checkbox"]{
    margin-top:27px;
}

.addressDiv-content table tbody tr td:not(:last-child):has(.error-message) + td input[type="checkbox"] {
    margin-top: 0;
}

.addressDiv-content table tbody tr td input{
    margin-top:20px;
}

.addressDiv-content table tbody tr td .preview-button{
    margin-top:2px;
    background: #004747;
}

.addressDiv-content table tbody tr td:not(:last-child):has(.error-message) + td .preview-button {
    margin-top: 0;
}

.addressDiv-content table tbody tr td .delete-button{
    margin-top:9px;
    color:#fff;
}

.addressDiv-content table tbody tr td:last-child:not(:has(.error-message)) .delete-button {
    margin-top: 0;
}

.modal-save-button{
    margin-top:20px;
    background: #004747;;
    color:#fff;
}

.textalign{
    font-weight:bold;
    text-align: center !important;
}

.inputForm1{
    width:125px;
   margin-left:30px; 
}

.inputForm2{
    width:400px;
    margin-left:10px; 
}

.addBtn{
  position: relative;
  top:10px;
  left: 10px;
}

.buttns{
    position: relative;
    top:10px;
    left:30px;
}

.pagination{
    display: flex;
    justify-content: center;
}

.paginationBtn{
    background-color: white;
    border: none;
    margin: 0 20px;
}

:hover.paginationBtn{
    background-color: #004747;
    color:white;
    border-radius:10rem;
}

.actBtn{
    border: none;
}

.input-error {
    border: 2px solid #C70039;
}
   
.error-message {
    color: #C70039;
    font-size: 0.8rem;
    margin-top: 0.2rem;
}

.babyModel-block{
    background: #F6F7FC;
    margin-top: 20px;
    padding:16px;
    border-radius: 10px;
}

.babyModel-block-data{
    margin-top:12px;
}

.babyModel-block-data input{
    font-size:11px;
    margin-top:14px;
}

.babyModel-block-data img{
    margin-top:10px;
}
.addressDiv-content table tbody tr td select{
    margin-top: 20px;
    padding: 1px 2px;
    color: #000;
    height:30px;
}


@media only screen and (max-width: 768px) {
    .btn1{
        margin-right: 0;
     }
     .addressDiv-content {
        overflow-x: scroll;
    }
}
@media screen and (min-width: 3000px) and (min-height: 2000px) {
    .addressDiv-content {
        overflow-x: hidden;
    }
}


