body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.quote-scan-record {
  margin-top: 24px;
}

input#cb1,
input#cb2,
input#cb3,
input#cb4,
input#cb5,
input#cb6,
input#cb7,
input#cb8,
input#cb9 {
  display: none;
}

.tab-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.35s;
  height: auto;
}

.tab input:checked ~ .tab-content {
  max-height: 20rem;
}

.tab input:checked ~ .tab-content.third,
.tab input:checked ~ .tab-content.fourth {
  max-height: none;
}

/* Visual styles */

.tab-label,
.tab-close {
  display: flex;
  cursor: pointer;
}

.tab-label h2 {
  font-family: "Satoshi Medium";
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0em;
  margin-bottom: 0;
  padding-bottom: 16px;
  color: #032020;
}

.tab-label {
  justify-content: space-between;
  padding: 1rem;
}

.tab-label::after {
  content: "\276F";
  width: 1em;
  height: 1em;
  text-align: center;
  transform: rotate(90deg);
  transition: all 0.35s;
}

.tab input:checked + .tab-label::after {
  transform: rotate(270deg);
}

.tab-content p {
  margin: 0;
  padding: 16px 24px 16px 16px;
}

.tab-close {
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}

.tab input:not(:checked) + .tab-label:hover::after {
  animation: bounce 0.5s infinite;
}

.formDivquote {
  display: flex;
  gap: 24px;
}

.scandiv {
  margin-top: 24px;
}

.scan-record {
  background-color: white;
  border-radius: 10px;
  margin-top: 24px;
}

.rows-content.padding {
  padding: 0;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 22px;
  height: 20px;
  border: 1px solid #032020;
  outline: none;
  position: relative;
  background-color: #032020;
  opacity: 15%;
  transform: scale(1.2);
}

input[type="checkbox"]:checked {
  background-color: black;
  opacity: 100%;
}

input[type="checkbox"]::before {
  content: "\2713";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #fff;
  visibility: hidden;
}

input[type="checkbox"]:checked::before {
  visibility: visible;
}

.main-wrap {
  position: relative;
}

.blur {
  filter: blur(1px);
}

.loader-overlay {
  position: relative;
  top: 700px;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

/* drone */
.model .drone {
  margin-top: 23px;
}

.planning-content.drone.planning-content .first-row {
  display: block !important;
}

.planning-content .drone .rows-content {
  width: 300px !important;
}
.planning-content.drone {
  display: flex;
  gap: 21px;
}

.modal1 {
  .modal-dialog {
    max-width: 75% !important;
  }
  .modal-header h2{
    padding-top: 5px;
    padding-bottom: 0px;
  }
  .modal-content {
    margin-top: 3% !important;
    padding: 7px 7px !important;
    b {
      font-size: 16px;
      font-family: sans-serif;
    }
    h5,h6{
      margin-top: 10px;
    }
  }
}
.main-record.withTimebar{
  width:80%;
}
@media only screen and (max-width: 768px) {
  .main-record.withTimebar{
    width:100%;
  }
  .quote-scan-content .third-block .img-block .image-wrapper img{ 
    width:100%;
    height:10vh;
  }
}